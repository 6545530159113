import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { fetchAdminApplicationsListFailure, fetchAdminApplicationsListSuccess } from "../../actions/adminApplicationsListActions/adminApplicationsListActions";
import { adminApplicationsListTypes } from "../../ActionTypes/adminApplicationsListTypes";

const getAdminApplicationsList = (data: any) => { 
  const config = {
    headers: { 
      Prefer: 'example=example-1', Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthAdminToken 
    }
  };
  return axios.get(API_BASE_ADDRESS + '/admin/applications?page='  + data.page + '&keyword=' + data.keyword + '&status_id=' + data.status_id + '&per=' + 50, config); 
}


function* fetchAdminApplicationsListSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getAdminApplicationsList, action.data);
    yield put(
      fetchAdminApplicationsListSuccess({
        applications: response.data.applications,
        pagenation: response.data.pagenation,
        status_list: response.data.status_list,
      })
    );
  } catch (e:any) {
    yield put(
      fetchAdminApplicationsListFailure({
        error: e.message
      })
    );
  }
}

function* AdminApplicationsListSaga() {
  yield takeLatest(adminApplicationsListTypes.FETCH_ADMIN_APPLICATIONS_LIST_REQUEST, fetchAdminApplicationsListSaga);
}
export default AdminApplicationsListSaga;