import { useNavigate } from "react-router-dom";
import MypageSideBar from "../../../components/MypageSideBar/MypageSideBar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchLoginRequest } from "../../../redax/actions/loginActions/loginActions";
import { RootState } from "../../../redax/reducers/rootReducer";
import { fetchRecruitmentsCompanyRequest } from "../../../redax/actions/recruitmentsCompanyActions/recruitmentsCompanyActions";
import SelectRecruitmentStatus from "../../../components/SelectRecruitmentStatus/SelectRecruitmentStatus";
import { fetchRecruitmentsCopyRequest } from "../../../redax/actions/recruitmentsCopyActions/recruitmentsDetailActions";
import { fetchRecruitmentsDeleteRequest } from "../../../redax/actions/recruitmentsDeleteActions/recruitmentsDeleteActions";
import { fetchRecruitmentsStatusChangeRequest } from "../../../redax/actions/recruitmentsStatusChangeActions/recruitmentsStatusChangeActions";
import Loader from "../../loader/loader";

export default function RecruiterRecruitments() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginData = useSelector((state: RootState) => state.login);
  const recruitmentsCompany = useSelector(
    (state: RootState) => state.recruitmentsCompany
  );
  const recruitmentsCopy = useSelector(
    (state: RootState) => state.recruitmentsCopy
  );
  const recruitmentsDeleteRes = useSelector(
    (state: RootState) => state.recruitmentsDelete
  );
  const recruitmentsStatusChangeRes = useSelector(
    (state: RootState) => state.recruitmentsStatusChange
  );

  const [familyName, setFamylyName] = useState(null);
  const [resourceId, setResourceId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [recruitments, setRecruitments] = useState<any>([]);
  const [pages, setPages] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [isActive, setIsActive] = useState(false);
  const [mySelected, setMySelected] = useState<number | null>(null);
  const [loadScreen, setLoadScreen] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('jobParachannelAuthToken')) {
      dispatch(fetchLoginRequest('', '',''))
    } else (
        navigate('/recruiter/login/')
    )
  }, [dispatch]);

  useEffect(() => {
    if (loginData.pending) {
      if (!loginData.login.is_init_complete) {
        navigate("/recruiter/init/");
      }
      setResourceId(loginData.login.resource.id);
      setFamylyName(loginData.login.resource.family_name);
      setCompanyId(loginData.login.company.id);
      setIsActive(loginData.login.is_active);
    }
  }, [loginData.pending]);

  useEffect(() => {
    if (companyId !== null) {
      dispatch(fetchRecruitmentsCompanyRequest(companyId, page));
    }
  }, [companyId, page]);

  useEffect(() => {
    if (recruitmentsCompany !== undefined && recruitmentsCompany.pending) {
      setLoadScreen(false);
      setRecruitments(recruitmentsCompany.company.recruitments);
      setPages(recruitmentsCompany.company.pagenation.total_pages);
    } else if (recruitmentsCompany.error != null) {
      setLoadScreen(false);
      navigate("/error?code=500");
    }
  }, [recruitmentsCompany]);

  function logout() {
    if (localStorage.entryFlag) {
      localStorage.removeItem("entryFlag");
    }
    if (localStorage.jobParachannelAuthToken) {
      localStorage.removeItem("jobParachannelAuthToken");
      navigate("/");
      window.location.reload();
    }
  }

  function copyAlert(id: any, title: any) {
    if (!isActive) {
      if (window.confirm("採用活動をおやすみ中です。再開しますか？")) {
        navigate("/recruiter/settings/");
      }
    } else {
      if (
        window.confirm("以下の求人を複製しますか？\n" + "【" + title + "】")
      ) {
        setLoadScreen(true);
        dispatch(fetchRecruitmentsCopyRequest(id));
      }
    }
  }

  useEffect(() => {
    if (recruitmentsCopy.pending) {
      if (recruitmentsCopy.recruitmentsCopy.id !== null) {
        navigate(
          "/recruiter/recruitments/" + recruitmentsCopy.recruitmentsCopy.id
        );
      } else {
        setLoadScreen(false);
        window.alert("サーバーエラー");
      }
    } else if (recruitmentsCopy.error != null) {
      setLoadScreen(false);
      navigate("/error?code=500");
    }
  }, [recruitmentsCopy]);

  function deleteAlert(id: any, title: any) {
    if (window.confirm("以下の求人を削除しますか？\n" + "【" + title + "】")) {
      setLoadScreen(true);
      dispatch(fetchRecruitmentsDeleteRequest(id));
      setTimeout(function () {
        window.location.reload();
      }, 1500);
    }
  }

  const updateStatus = async (id: any, value: any) => {
    if (!id) {
      throw new Error("求人IDが不正です");
    }
    let result = false;
    if (value == 2) {
      // result = window.confirm('・会社概要プレビューを確認していただけましたか。\n・求人票の記入に誤りはありませんか。\n本当に公開しますか？');
      navigate("/recruiter/recruitments/" + id + "/");
      return;
    } else if (value == 3) {
      result = window.confirm(
        "ステータスを応募終了に変更すると、選考途中の応募者が全員不採用となり、募集の再開はできなくなります。\n本当に実行しますか？"
      );
    } else {
      result = window.confirm("求人ステータスを変更しますか？");
    }
    if (!result) {
      value = null;
      return;
    }
    setLoadScreen(true);
    dispatch(fetchRecruitmentsStatusChangeRequest(id, value));
    setTimeout(function () {
      window.location.reload();
    }, 500);
  };

  const pageIncrement = () => {
    if (page >= pages) {
      setPage(pages);
      return;
    }
    setPage((prevPage) => prevPage + 1);
    setLoadScreen(true);
    dispatch(fetchRecruitmentsCompanyRequest(companyId, page));
  };

  const pageDecrement = () => {
    if (page <= 1) {
      setPage(1);
      return;
    }
    setPage((prevPage) => prevPage - 1);
    setLoadScreen(true);
    dispatch(fetchRecruitmentsCompanyRequest(companyId, page));
  };

  const updatePage = (p: any) => {
    setPage(p);
    setLoadScreen(true);
    dispatch(fetchRecruitmentsCompanyRequest(companyId, page));
  };

  return (
    <main id="company">
      <MypageSideBar
        resource={resourceId}
        familyName={familyName}
        currentList="RecruiterList"
      />
      {loadScreen ? <Loader /> : <></>}
      <section>
        <ul className="bread_crumb">
          <li>
            <a href="/">トップ</a>
          </li>
          <li>
            <a href="/recruiter/">企業様マイページ</a>
          </li>
          <li>
            <a href="#">求人管理</a>
          </li>
        </ul>
        <div className="ttl_box">
          <h2 className="ttl">求人管理 ＞ 一覧</h2>
          <ul>
            <li>
              <a href="#" onClick={logout}>
                <img src="/assets/img/company/icon02.svg" alt="" />
                ログアウト
              </a>
            </li>
          </ul>
        </div>
        <div className="jobs">
          <a href="/recruiter/recruitments/create/" className="btn type3 link">
            求人を新規作成する
          </a>
          <div className="edit_box">
            <div>
              <div className="icon"></div>
              <ul className="pagination">
                <li className={`arrow prev ${page <= 1 ? "disabled" : ""}`}>
                  <a onClick={pageDecrement}>
                    <img src="/assets/img/company/icon06.svg" alt="次へ" />
                  </a>
                </li>
                {Array.from({ length: pages }, (_, i) => i + 1).map((p) => (
                  <li key={p} className={`page ${page === p ? "active" : ""}`}>
                    <a href="#" onClick={() => updatePage(p)}>
                      <span>{p}</span>
                    </a>
                  </li>
                ))}
                <li className={`arrow next ${page >= pages ? "disabled" : ""}`}>
                  <a href="#" onClick={pageIncrement}>
                    <img src="/assets/img/company/icon07.svg" alt="前へ" />
                  </a>
                </li>
              </ul>
            </div>
            {recruitments.length == 0 ? (
              <div v-if="recruitments.length == 0">
                <p>まだ求人はありません</p>
              </div>
            ) : (
              <ul className="list_box">
                {recruitments.map((data: any) => (
                  <li key={data.id}>
                    <div>
                      <div className="cont1">
                        <div className="img_box">
                          {" "}
                          <a href={"/recruiter/recruitments/" + data.id + "/"}>
                            {" "}
                            <img src={data.thumbnail} alt="" />{" "}
                          </a>
                        </div>
                        <div className="text_box">
                          <a href={"/recruiter/recruitments/" + data.id + "/"}>
                            <h3>
                              <span className="id_text">求人ID: {data.id}</span>
                              {data.job_title} / {data.company_name}
                            </h3>
                            <p>
                              {data.prefecture}
                              {data.city} 【{" "}
                              {Number(
                                data.lower_monthly_basic_salary
                              ).toLocaleString()}{" "}
                              〜{" "}
                              {Number(
                                data.upper_monthly_basic_salary
                              ).toLocaleString()}{" "}
                              円】
                            </p>
                          </a>
                        </div>
                      </div>
                      <div className="cont2">
                        <a
                          href={"/recruiter/applications/#" + data.id}
                          className="btn type3 blue"
                        >
                          応募者一覧
                        </a>
                        {/* 
                        <p className="type3 blue"  style={{color: "#00a0e9"}}>
                          応募者数 {data.statistics.waiting}人
                        </p>
                        <p className="type3 blue" style={{color: "#00a0e9"}}>
                            PV数 {data.view_count}
                        </p>
                        
                        {data.current_status.id == 2 ||
                        data.current_status.id == 4 ? (
                          <a
                            href={"/recruitments/" + data.id}
                            className="btn type3 blue"
                          >
                            公開中の求人票を見る
                          </a>
                        ) : (
                          <></>
                        )}
                        */}
                        <a
                          onClick={() => copyAlert(data.id, data.job_title)}
                          className="btn type3 orange"
                        >
                          <img src="/assets/img/company/icon33.png" alt="" />
                          複製する
                        </a>
                        {data.published_at && data.current_status.id !== 1 ? (
                          <p style={{marginTop: "15px"}}>公開日時：{data.published_at}</p>
                        ) : (
                          <></>
                        )}
                        <p style={{marginTop: "15px"}}>最終編集：{data.updated_at}</p>
                        <select
                          name="status"
                          value={data.current_status.id ?? ""}
                          onChange={(e) => {
                            setMySelected(Number(e.target.value));
                            updateStatus(data.id, Number(e.target.value));
                          }}
                          disabled={
                            data.update_statuses === undefined ||
                            data.update_statuses == null ||
                            data.update_statuses.length == 0
                              ? true
                              : false
                          }
                        >
                          <option value="" disabled hidden>
                            選択してください
                          </option>
                          <option value="" selected hidden>
                            {data.current_status.name}
                          </option>
                          {data.update_statuses.map((status: any) => (
                            <option key={status.id} value={status.id}>
                              {status.name}
                            </option>
                          ))}
                        </select>
                        <a
                          href={"/recruiter/recruitments/" + data.id}
                          className="btn type3 edit"
                        >
                          編集
                        </a>
                        <a
                          onClick={() => deleteAlert(data.id, data.job_title)}
                          className="del"
                        >
                          <img
                            src="/assets/img/company/icon05.svg"
                            alt="削除"
                          />
                        </a>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {pages > 1 ? (
              <div className="bottom">
                <div className="icon"></div>
                <ul className="pagination">
                  <li className={`arrow prev ${page <= 1 ? "disabled" : ""}`}>
                    <a onClick={pageDecrement}>
                      <img src="/assets/img/company/icon06.svg" alt="次へ" />
                    </a>
                  </li>
                  {Array.from({ length: pages }, (_, i) => i + 1).map((p) => (
                    <li
                      key={p}
                      className={`page ${page === p ? "active" : ""}`}
                    >
                      <a href="#" onClick={() => updatePage(p)}>
                        <span>{p}</span>
                      </a>
                    </li>
                  ))}
                  <li
                    className={`arrow next ${page >= pages ? "disabled" : ""}`}
                  >
                    <a href="#" onClick={pageIncrement}>
                      <img src="/assets/img/company/icon07.svg" alt="前へ" />
                    </a>
                  </li>
                </ul>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </section>
    </main>
  );
}
