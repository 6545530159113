import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AdminNav from '../adminTop/AdminNav';
import { RootState } from "../../redax/reducers/rootReducer";
import dayjs from 'dayjs';
import Loader from '../loader/loader';
import { fetchAdminInvoiceDetailRequest } from '../../redax/actions/adminInvoiceDetailActions/adminInvoiceDetailActions';
import { fetchAdminInvoiceDetailPostRequest } from '../../redax/actions/adminInvoiceDetailPostActions/adminInvoiceDetailPostActions';


const AdminInvoiceDetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();    
    const { id } = useParams();
    const adminInvoiceDetail = useSelector((state: RootState) => state.adminInvoiceDetail);
    const adminInvoiceDetailPost = useSelector((state: RootState) => state.adminInvoiceDetailPost);
    const [loadScreen, setLoadScreen] = useState(true);
    const [planName, setPlanName] = useState('');
    const [basePrice, setBasePrice] = useState('');
    const [totalPrice, setTotalPrice] = useState('');
    const [scoutAgency, setScoutAgency] = useState('');
    const [minGuarantee, setMinGuarantee] = useState('');
    const [optionName1, setOptionName1] = useState('');
    const [optionName2, setOptionName2] = useState('');
    const [price1, setPrice1] = useState<any>(0);
    const [price2, setPrice2] = useState<any>(0);
    const [price, setPrice] = useState<any>(0);
    const [price0, setPrice0] = useState<any>(0);




    const [options, setOptions] = useState<any>([]);
    const [tmpOptions, setTmpOptions] = useState<any>([]);

    const [check, setCheck] = useState(false);


    
    useEffect(() => {
        if (!localStorage.getItem('jobParachannelAuthAdminToken')) {
          navigate('/admin/login');
        } else {
          dispatch(fetchAdminInvoiceDetailRequest(id));
        //   setFirstLoad(true);
        }    
      }, [dispatch]);


      useEffect(() => {
        if (adminInvoiceDetail.pending) {
            setLoadScreen(false);
            setPlanName(adminInvoiceDetail.invoiceDetail.plan_name);
            setBasePrice(adminInvoiceDetail.invoiceDetail.base_price);
            setTotalPrice(adminInvoiceDetail.invoiceDetail.total_price);
            setOptions(adminInvoiceDetail.invoiceDetail.options);
            
        }  
      }, [adminInvoiceDetail]);


      useEffect(() => {
        if (adminInvoiceDetailPost.pending && check) {
            setLoadScreen(false);
                // navigate('/admin/invoice_plan/' + id);
                window.location.href = '/admin/invoice_plan/' + id;
        }  
      }, [adminInvoiceDetailPost]);

      useEffect(() => {
        if(options.length > 0 ){
            let tmp = [];
            for(let i = 0; i < options.length; i++){
                if(options[i].name == "スカウト代行"){
                    let str = options[i].name + '50件' + options[i].price.toLocaleString() + '円/月 (税別)';
                    setScoutAgency(str);
                    setPrice( options[i].price);
                } else if (options[i].name == "最低保証"){
                    let str1 = options[i].name + options[i].price.toLocaleString() + '円/月 (税別)';
                    setMinGuarantee(str1);
                    setPrice0( options[i].price);
                } else {
                    tmp.push(options[i]);
                }
            }
            setTmpOptions(tmp);
        }
      }, [options]);


      useEffect(() => {
        if(tmpOptions.length > 0 ){
            for(let i = 0; i < tmpOptions.length; i++){
              if(tmpOptions[0]){
                setOptionName1(tmpOptions[0].name);
                if(tmpOptions[0].price !==0){
                    setPrice1(tmpOptions[0].price)
                } else if (tmpOptions[0].discount !==0){
                    setPrice1(tmpOptions[0].discount * -1)
                }
              }
              if(tmpOptions[1]){
                setOptionName2(tmpOptions[1].name);
                if(tmpOptions[1].price !==0){
                    setPrice2(tmpOptions[1].price)
                } else if (tmpOptions[1].discount !==0){
                    setPrice2(tmpOptions[1].discount * -1)
                }
              }
            }
        }
      }, [tmpOptions]);

      function companyEdit() {
        let tmpoptions = options.filter((option: { name: string; }) => option.name === "スカウト代行" || option.name === "最低保証");
        let tmp1 = {
            name: optionName1 || "",
            price: price1 > 0 ? price1 : 0,
            quantity: 1,
            discount: price1 < 0 ? -price1 : 0,
        };


    
        let tmp2 = {
            name: optionName2 || "",
            price: price2 > 0 ? price2 : 0,
            quantity: 1,
            discount: price2 < 0 ? -price2 : 0,
        };
    
        if (tmp1.name) {
            const existsTmp1 = tmpoptions.some((option: { name: string; }) => option.name === tmp1.name);
            if (!existsTmp1) {
                tmpoptions.push(tmp1);
            } else {

                tmpoptions = tmpoptions.map((option: { name: string; price: number; discount: number; }) => {
                    if (option.name === tmp1.name) {
                        option.price = tmp1.price;
                        option.discount = tmp1.discount;
                    }
                    return option;
                });
            }
        }

        if (tmp1.price !== 0) {
            const existsTmp1 = tmpoptions.some((option: { price: number; }) => option.price === tmp1.price);
            if (!existsTmp1) {
                tmpoptions.push(tmp1);
            } else {
                tmpoptions = tmpoptions.map((option: { name: string; price: number; discount: number; }) => {
                    if (option.price === tmp1.price) {
                        option.price = tmp1.price;
                        option.discount = tmp1.discount;
                    }
                    return option;
                });
            }
        }

        if (tmp1.discount !== 0) {
            const existsTmp1 = tmpoptions.some((option: { discount: number; }) => option.discount === tmp1.discount);
            if (!existsTmp1) {
                tmpoptions.push(tmp1);
            } else {
                tmpoptions = tmpoptions.map((option: { name: string; price: number; discount: number; }) => {
                    if (option.discount === tmp1.discount) {
                        option.price = tmp1.price;
                        option.discount = tmp1.discount;
                    }
                    return option;
                });
            }
        }
        
    
        if (tmp2.name) {
            const existsTmp2 = tmpoptions.some((option: { name: string; }) => option.name === tmp2.name);
            if (!existsTmp2) {
                tmpoptions.push(tmp2);
            } else {
                tmpoptions = tmpoptions.map((option: { name: string; price: number; discount: number; }) => {
                    if (option.name === tmp2.name) {
                        option.price = tmp2.price;
                        option.discount = tmp2.discount;
                    }
                    return option;
                });
            }
        }

        if (tmp2.price !== 0) {
            const existsTmp2 = tmpoptions.some((option: { price: number; }) => option.price === tmp2.price);
            if (!existsTmp2) {
                tmpoptions.push(tmp2);
            } else {
                tmpoptions = tmpoptions.map((option: { name: string; price: number; discount: number; }) => {
                    if (option.price === tmp2.price) {
                        option.price = tmp2.price;
                        option.discount = tmp2.discount;
                    }
                    return option;
                });
            }
        }

        if (tmp2.discount !== 0) {
            const existsTmp2 = tmpoptions.some((option: { discount: number; }) => option.discount === tmp2.discount);
            if (!existsTmp2) {
                tmpoptions.push(tmp2);
            } else {
                tmpoptions = tmpoptions.map((option: { name: string; price: number; discount: number; }) => {
                    if (option.discount === tmp2.discount) {
                        option.price = tmp2.price;
                        option.discount = tmp2.discount;
                    }
                    return option;
                });
            }
        }
    
        const data = {
            invoice_id: id,
            // discount: adminInvoiceDetail.invoiceDetail.discount,
            options: tmpoptions,
        };
    
        setCheck(true);
        dispatch(fetchAdminInvoiceDetailPostRequest(data));
    }
    

      function onChangeName1 (e:any) {
        setOptionName1(e);
      }

      function onChangeName2 (e:any) {
        setOptionName2(e);
      }

      function onChangePrice1 (e:any) {
        setPrice1(e);
      }

      function onChangePrice2 (e:any) {
        setPrice2(e);
        
      }

      
  return (
    <main id='adminHome'>
        <AdminNav/>
        {loadScreen ? <Loader></Loader> : <></>}
        <section className='invoiceDetail'>
            <div>

                <div className="table-container">
                    <h1 className='titleInvoice'>現在の金額内訳</h1>

                    <table className="invoice-detail-table">
                        <tbody>
                        <tr style={{ height: '100px', verticalAlign: 'middle' }}>
                            <td>ご利用内容</td>
                            <td className='tableColor'>{planName}</td>
                        </tr>
                        <tr>
                            <td>基本料金</td>
                            <td className='tableColor'>{basePrice.toLocaleString()}</td>
                        </tr>
                        <tr>
                            <td>スカウト代行</td>
                            <td className='tableColor'>{scoutAgency}</td>
                        </tr>
                        <tr>
                            <td>エントリー最低保証</td>
                            <td className='tableColor'>{minGuarantee}</td>
                        </tr>
                        <tr>
                            <td>合計金額</td>
                            <td className='tableColor'>{totalPrice.toLocaleString()}</td>
                        </tr>
                        </tbody>
                    </table>
                    <div>
                        <h1 className='titleInvoice'>追加部分</h1>
                    </div>
                    <div className='dFlex'>
                        <div style={{marginRight:'20px'}}>
                            <label>項目名  <input  type="text" name="optionName" value={optionName1 || ''} onChange={(e)=> onChangeName1(e.target.value)} /> </label>
                        </div>
                        <div>
                            <label>追加勘定(割引の場合は-をつけて数値を入れてください) <input type="number" placeholder='例)3000円'  value={price1 || null} name="optionPrice" onChange={(e)=> onChangePrice1(Number(e.target.value))} /> </label>
                        </div>
                    </div>
                    <div className='dFlex'>
                        <div style={{marginRight:'20px'}}>
                            <label>項目名  <input  type="text" name="optionName2"  value={optionName2 || ''} onChange={(e)=> onChangeName2(e.target.value)} /> </label>
                        </div>
                        <div>
                            <label>追加勘定(割引の場合は-をつけて数値を入れてください) <input type="number" name="optionPrice2"  placeholder='例)3000円'   value={price2 || null} onChange={(e)=> onChangePrice2(Number(e.target.value))} /> </label>
                        </div>
                    </div>
                    <h1 className='titleInvoice'>変更後の合計金額</h1>
                    <table className="invoice-detail-table">
                        <tbody>
                        <tr>
                            <td>変更後の小計</td>
                            <td className='tableColor'>
                                {(adminInvoiceDetail.invoiceDetail.base_price + adminInvoiceDetail.invoiceDetail.discount + (price + price0 + price2 + price1) ).toLocaleString()}
                            </td>
                        </tr>
                        <tr>
                            <td>変更後の合計金額</td>
                            <td className='tableColor'>{((adminInvoiceDetail.invoiceDetail.base_price + adminInvoiceDetail.invoiceDetail.discount + (price + price0 + price2 + price1) ) * adminInvoiceDetail.invoiceDetail.tax_rate /100  + adminInvoiceDetail.invoiceDetail.base_price + adminInvoiceDetail.invoiceDetail.discount + (price + price0 + price2 + price1)).toLocaleString()}</td>
                        </tr>
                        </tbody>
                    </table>
                    <div>
                        <button className='invoiceDtlBtn'  onClick={()=>companyEdit()}>変更</button>
                    </div>
                </div>
            </div>
        </section>
    </main>
  )
}

export default AdminInvoiceDetail
