import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../../redax/reducers/rootReducer";
import { fetchLoginRequest } from "../../../../redax/actions/loginActions/loginActions";
import MypageSideBar from "../../../../components/MypageSideBar/MypageSideBar";
import { fetchScoutsCandidateRequest } from "../../../../redax/actions/scoutsCandidateActions/scoutsCandidateActions";
import { fetchScoutsRequest } from "../../../../redax/actions/scoutsActions/scoutsActions";
import Loader from "../../../loader/loader";
import './candidate.scss';


function CandidateUnsentIndex() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [resourceId, setResourceId] = useState(null);
    const loginData = useSelector((state: RootState) => state.login);
    const [familyName, setFamylyName] = useState(null);
    const [current_page, setCurrentPage] = useState(0);
    const [total_pages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);
    const [status, setStatus] = useState('unsent');
    const [sort, setSort] = useState('');
    const [scoutId, setScoutId] = useState('');
    const [selectedApplicants, setSelectedApplicants] = useState<any>([]);
    const [selectedApplicantIds, setSelectedApplicantIDs] = useState<any>([]);
    const [scouts, setScouts] = useState<any>([]);
    const [applicants, setApplicants] = useState([]);
    const candidates = useSelector((state: RootState) => state.scoutsCandidate);
    const scoutsApplicants = useSelector( (state: RootState) => state.scouts);
    const [loadScreen, setLoadScreen] = useState(true);
    const [allCheck, setAllcheck] = useState(false);
    const [limitCount, setLimitCount] = useState(0);
    const [scoutDisabled, setScoutDisabled] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const urlStatus = queryParams.get('status');
    const urlscoutId = queryParams.get('scoutId');

    let sortList = [
        { text: '並び替え', value: '' },
        { text: '年齢が低い順', value: 'birthday_low' },
        { text: '年齢が高い順', value: 'birthday_high' },
        { text: '登録日時が新しい順', value: 'created_at' },
        { text: 'ログイン日時が新しい順', value: 'active' },
        { text: '相性のマッチ度の高い順', value: 'compatibility' },
    ];
    let statusList = [
        { text: 'ステータス', value: '' },
        { text: '未送信', value: 'unsent' },
        { text: '送信', value: 'sent' },
        { text: '開封', value: 'opened' },
    ];

    useEffect(() => {
        if (localStorage.getItem('jobParachannelAuthToken')) {
          dispatch(fetchLoginRequest('', '',''))
        } else (
            navigate('/recruiter/login/')
        )
      }, [dispatch]);

    useEffect(() => {
        if (loginData.pending) {
            if(!loginData.login.is_init_complete) {
                navigate('/recruiter/init/');
            }
            setResourceId(loginData.login.resource.id);
            setFamylyName(loginData.login.resource.family_name);
            setLimitCount(loginData.login.scout_today_limit);
            if (loginData.login.scout_today_limit <= 0) {
                setScoutDisabled(true);
                window.confirm("本日のスカウト可能残数0名になりました");
              } 
            dispatch(fetchScoutsRequest(0, 1));
            if(urlStatus && urlscoutId){
                dispatch(fetchScoutsCandidateRequest(0, 'get', 'page=' + page +'&status=' + urlStatus + '&scout_id=' + urlscoutId));
            } else {
                dispatch(fetchScoutsCandidateRequest(0, 'get', 'page=' + page +'&status=unsent' + '' + '&scout_id=' + ''));
            }
           
        }
    }, [loginData.pending]);

    useEffect(() => {
        if (candidates !== undefined && candidates.pending) {            
            setApplicants(candidates.candidates.company_scout_candidates);
            if(candidates.candidates.pagenation != undefined) {
                setTotalPages(candidates.candidates.pagenation.total_pages);
                setCurrentPage(candidates.candidates.pagenation.current_page);
            }
            setLoadScreen(false);
        } else if(candidates.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [candidates]);

    useEffect(() => {
        if (scoutsApplicants !== undefined && scoutsApplicants.pending) {
            let c = scoutsApplicants?.scouts?.scouts || [];
            if (Array.isArray(c) && !c.some(item => item.id === '')) {
                c.unshift({ id: '', title: '求人' });
            }
            setScouts(c);
        } else if(scoutsApplicants.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [scoutsApplicants]);
    
    function logout() {
        if (localStorage.entryFlag) {
            localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
            localStorage.removeItem('jobParachannelAuthToken')
            navigate('/');
            window.location.reload();
        }
    }

    const shaping_pagenation = () => {
        if (total_pages === null) return []; // Add a check for total_pages being null
    
        if (total_pages <= 11) {
          return Array.from({ length: total_pages }, (_, index) => index + 1);
        } else if (current_page < 7) {
          return Array.from({ length: 11 }, (_, index) => index + 1);
        } else if (total_pages - current_page <= 5) {
          const diff_current_total = total_pages - current_page;
          const pagenation_start = current_page - (11 - (diff_current_total + 1));
          const pagenation_end = current_page + diff_current_total;
          return Array.from({ length: pagenation_end - pagenation_start + 1 }, (_, index) => pagenation_start + index);
        } else {
          const pagenation_start = current_page - 5;
          const pagenation_end = current_page + 5;
          return Array.from({ length: pagenation_end - pagenation_start + 1 }, (_, index) => pagenation_start + index);
        }
    };

    function isCandidatePage() {
        return window.location.pathname.indexOf('candidate') != -1;
    }

    /**
     * 求職者詳細ページの時true、そうでない時false
     **/
    function checkApplicantDetailsPage() {
        return window.location.pathname.search(/scouts\/applicant\/\d+$/g) != -1
    }

    function toggleCheckAll() {
        if (!allCheck) {
            setAllcheck(true);
            // 全ての求職者のIDを候補者保存API用配列に入れる
            let c = selectedApplicantIds;
            let c2 : any =[];
            applicants.map((obj: any) => {
                c.push(obj.applicant.id);
                c2.push(obj.applicant);
                            
            });
            setSelectedApplicantIDs(c);
            setSelectedApplicants(c2);
            // 全ての求職者のオブジェクトをスカウト候補画面遷移時パラメータ用配列に入れる
            // setSelectedApplicants(applicants.slice());
        } else {
            setAllcheck(false);
            // checkbox表示用を空にする
            setSelectedApplicantIDs([]);
            // データ送信時用を空にする
            setSelectedApplicants([]);
    
        }
    }

    function checkSelect(id: any) {
        let c = selectedApplicantIds;
        for(let i = 0; i < c.length; i++) {
            if(c[i] == id) {
                return true;
            }
        }
        return false;
    }

    function fetchApplicants(e: any, name: any) {
        setLoadScreen(true);
        if(name == 'status') {
            setStatus(e.target.value);
            if (e.target.value == '' || e.target.value == 'unsent') {
                setScoutId('');
                dispatch(fetchScoutsCandidateRequest(0, 'get', 'page=' + page + '&scout_id=' + '&status=' + e.target.value + '&sort=' + sort));
            } else {
                dispatch(fetchScoutsCandidateRequest(0, 'get', 'page=' + page + '&scout_id=' + scoutId + '&status=' + e.target.value + '&sort=' + sort));
            }            
        } else if(name == 'scout') {
            setScoutId(e.target.value);
            dispatch(fetchScoutsCandidateRequest(0, 'get', 'page=' + page + '&scout_id=' + e.target.value + '&status=' + status + '&sort=' + sort));
        } else {
            setSort(e.target.value);
            dispatch(fetchScoutsCandidateRequest(0, 'get', 'page=' + page + '&scout_id=' + scoutId + '&status=' + status + '&sort=' + e.target.value));
        }
    }

    function toggleCheckSelectedApplicants(app: any) {        
        let c = [];
        let c2 = [];
        let index = -1;
        for(let i = 0; i < selectedApplicants.length; i++) {
            c.push(selectedApplicants[i]);
        }
        for(let j = 0; j < selectedApplicantIds.length; j++) {
            c2.push(selectedApplicantIds[j]);
        }
        for(let k = 0; k < c2.length; k++) {
            if(c2[k] === app.id) {                
                index = k;
            }
        }
        if (index !== -1) {
          // 求職者1人削除
          c.splice(index, 1);
          c2.splice(index, 1);
        } else {
          // 求職者1人追加
          c.push(app);
          c2.push(app.id);
        }
        setSelectedApplicants(c);
        setSelectedApplicantIDs(c2);
    }

    const pageIncrement = () => {
        if (page >= total_pages) {
          setPage(total_pages);
          return;
        }
        setPage((prevPage) => prevPage + 1);
        setLoadScreen(true);
        dispatch(fetchScoutsCandidateRequest(0, 'get', 'page=' + (page+1) +'&status=unsent'));
    }

    const pageDecrement = () => {
        if (page <= 1) {
            setPage(1);
            return;
        }
        setPage((prevPage) => prevPage - 1);
        setLoadScreen(true);
        dispatch(fetchScoutsCandidateRequest(0, 'get', 'page=' + (page-1) +'&status=unsent'));
    }

    const updatePage = (p: any) => {
        setPage(p);
        setLoadScreen(true);
        dispatch(fetchScoutsCandidateRequest(0, 'get', 'page=' + p +'&status=unsent'));
    }

    function saveCandidates(selectedApplicantId: any, app: any) {
        if (selectedApplicants.length > limitCount) {
            window.alert('本日のスカウト可能残数を超えています')
            return
        }
        // スカウト候補に追加処理が求職者一覧と詳細で異なる
        // 一覧: 追加後画面遷移なし、ボタンを押せなくする
        // 詳細: 追加後画面遷移
        // 一覧でボタンを押せなくするため求職者一覧オブジェクトをstoreで持ちたい
        // store/scouts/index.jsで持って、URLを元に同モジュールのスカウト候補に追加処理で分岐させても良いが
        // 求職者一覧ページでしか使わない処理や求職者一覧オブジェクトをそこに書くのは混乱を招きそうなので
        // ページ単位でstoreのファイルを分けて、そこから共通の処理であるスカウト候補に追加処理が書かれてるscouts/index.jsのsaveCandidates()を呼ぶことにした
        if (isCandidatePage()) {
            // 求職者詳細ページの時
            dispatch(fetchScoutsCandidateRequest('', 'post', selectedApplicantId != 0 ? [selectedApplicantId] : selectedApplicantIds));
            if(selectedApplicantId == 0) {
                let c: any = [];
                for(let i = 0; i < selectedApplicants.length; i++) {
                    c.push(selectedApplicants[i]);
                }
                console.log("can",c)
                setTimeout( () => navigate('/recruiter/scouts/entry/', {state: {applicants: c, id: 0}}), 500);
            } else {
                setTimeout( () => navigate('/recruiter/scouts/entry/', {state: {id: selectedApplicantId, applicants: app}}), 500);
            }
        }
    }

    function loadEntry(app: any) {
        if (selectedApplicants.length > limitCount) {
            window.alert('本日のスカウト可能残数を超えています')
            return
        }
        navigate('/recruiter/scouts/entry/', {state: {applicants: [app.applicant], id: app.applicant.id}});
    }
      

    return (
      <main id="company">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="RecruiterList" />
        {loadScreen ? <Loader /> : <></>}
        <section>
            <ul className ="bread_crumb">
                <li><a href="/">トップ</a></li>
                <li><a href="/recruiter/">企業様マイページ</a></li>
            </ul>
            <div className="ttl_box">
                <h2 className="ttl">スカウト</h2>
                <ul>
                <li><a href="#" onClick={logout}><img src="/assets/img/company/icon02.svg" alt=""/>ログアウト</a></li>
                </ul>
            </div>
            <div id="scouts" className="candidate">
                <div className="edit_box">
                    <div>
                        <div className="icon"></div>
                        <ul className="pagination">
                            {current_page && current_page > 1 && (
                            <li className="arrow prev">
                                <a onClick={() => pageDecrement()}>
                                <img src="/assets/img/company/icon06.svg" alt="前へ" />
                                </a>
                            </li>
                            )}

                            {shaping_pagenation().map((n: any) => (
                            <li key={n} className={n === current_page ? 'active' : ''}>
                                <a onClick={() => updatePage(n)}>{n}</a>
                            </li>
                            ))}

                            {current_page && current_page < total_pages && (
                            <li className="arrow next">
                                <a onClick={() => pageIncrement()}>
                                <img src="/assets/img/company/icon07.svg" alt="次へ" />
                                </a>
                            </li>
                            )}
                        </ul>
                    </div>

                    <div className="cont">
                    <h3>本日のスカウト可能残数<span><span>{limitCount}</span>名</span></h3>
                    {/* <form>
                        <select v-model="status" onChange={(e) => fetchApplicants(e, 'status')}>
                        {statusList.map((status: any,index: any ) => (
                            <option value={status.value} key={index}>
                            { status.text }
                            </option>
                        ))}
                        </select>
                        {status == 'sent' || status == 'opened' ?
                        <select v-model="scoutId" onChange={(e) => fetchApplicants(e, 'scout')}>
                            {scouts.map((scout: any,index: any ) => (
                            <option value={scout.id} key={index}>
                            {scout.id == '' ? <>スカウト</>
                            : <>
                                スカウトID:{ scout.id }
                                {scout.recruitments.map((recruitment: any,index: any ) => (
                                <span>[{ recruitment.title }]</span>
                                ))}
                                </>}
                            </option>
                            ))}
                        </select>
                        : <></>}
                    </form> */}
                    {applicants ?
                    <h3>現在<span><span>{ applicants.length }</span>名</span>表示されています</h3>
                    : <></>}
                    </div>
                    <form className="list_item">
                    <select v-model="sort" onChange={(e) => fetchApplicants(e, 'sort')}>
                    {sortList.map((sortItem: any,index: any ) => (
                        <option value={sortItem.value} key={index}>
                        { sortItem.text }
                        </option>
                    ))}
                    </select>
                    {/* <!-- <p>検索結果 {{ applicantsData.pagenation.total_count }}件 / {{ applicantsData.pagenation.all_count }}件</p><br> --> */}
                    <ul>
                        <li className={!isCandidatePage() ? 'active' : ''}><a href="/recruiter/scouts/">人材一覧</a></li>
                        <li className={isCandidatePage() ? "active" : ""}> <a href="/recruiter/scouts/candidate-unsent/">お気に入り</a> </li>
                        <li><a href="/recruiter/scouts/candidate/">スカウト履歴</a></li>
                    </ul>
                    <div>
                        <label><input type="checkbox" v-model="allCheck" checked={allCheck} onChange={toggleCheckAll}/></label>
                        <p>全員にチェックを入れる</p>
                        {!isCandidatePage() ?
                        <a onClick={() => (console.log())} className={selectedApplicants.length == 0 ? 'btn green none-candidate' : 'btn green' }>スカウト候補に一括追加</a>
                        : <></>}
                    </div>
                    </form>
                    <ul className="list_box">
                    {applicants && applicants.map((applicant: any,index: any ) => (
                    <li key={applicant.applicant.id}>
                        <label><input type="checkbox" v-model="selectedApplicantIds" checked={checkSelect(applicant.applicant.id)} onChange={() => toggleCheckSelectedApplicants(applicant.applicant)}/></label>
                        <div className={applicant.applicant.active ? 'item_box online' : 'item_box'}>
                            <div>
                            <div>
                                {!checkApplicantDetailsPage() ?
                                    <a href={'/recruiter/scouts/applicant/' + applicant.applicant.id} target="_blank">
                                    <div className="img_box">
                                        <img src={applicant.applicant.face_photo} alt={applicant.applicant.family_name + applicant.applicant.first_name}/>
                                    </div>
                                    <h4>ID:{ applicant.applicant.id }</h4>
                                    <p>&#x2665; 相性{ applicant.applicant.compatibilities }%</p>
                                    <div className="tooltip_box">
                                        <div className="tooltip">
                                        <p className="icon"><img src="/assets/img/company/icon32.svg" alt=""/></p>
                                        <p className="text">※相性とは<br/>スキルや経験だけでなく、求職者の求める働き方と採用担当<br/>者の求める人物像との相性を加味して算出しています。</p>
                                        </div>
                                    </div>
                                    </a>                                
                                : (<>
                                <div className="img_box">
                                    <img src={applicant.applicant.face_photo} alt={applicant.applicant.family_name + applicant.applicant.first_name}/>
                                </div>
                                <h4>ID:{ applicant.applicant.id }</h4>
                                <p>&#x2665; 相性{ applicant.applicant.compatibilities }%</p>
                                    <div className="tooltip_box">
                                        <div className="tooltip">
                                            <p className="icon"><img src="/assets/img/company/icon32.svg" alt=""/></p>
                                            <p className="text">※相性とは<br/>スキルや経験だけでなく、求職者の求める働き方と採用担当<br/>者の求める人物像との相性を加味して算出しています。</p>
                                        </div>
                                    </div>
                                </>)}
                            </div>
                            <ul>
                                <li>
                                <span>障害種別<br className="sp_only"/>(手帳有無)</span>
                                {applicant.applicant.handicap_qualifications.length > 0 ?
                                    applicant.applicant.handicap_qualifications && applicant.applicant.handicap_qualifications.map((handicap_qualification: any ,i: any) => (
                                        <>{ handicap_qualification.handicap_type }(手帳{ handicap_qualification.disability_certificate_status })
                                        {i != Object.keys(applicant.applicant.handicap_qualifications).length - 1 ? <>/<br className="sp_only"/></> : <></>}
                                        </>
                                    ))
                                : <></>}
                                </li>
                                <li><span>就労支援有無</span>{ applicant.applicant.employment_support_agency }</li>
                                <li><span>勤務地希望</span>
                                {applicant.applicant.preferred_prefectures.length > 0 ?
                                    applicant.applicant.preferred_prefectures && applicant.applicant.preferred_prefectures.map((preferred_prefecture: any ,i: any) => (
                                        <>{preferred_prefecture}
                                        {(i != Object.keys(applicant.applicant.preferred_prefectures).length - 1) ? <>/<br className="sp_only"/></> : <></>}
                                        </>
                                    ))
                                : <>無し</>}
                                </li>
                            </ul>
                            </div>
                            <div className="btn_box">
                            {!isCandidatePage() ?
                            <a href="javascript:void(0)" onClick={(e) => saveCandidates(applicant.applicant.id, applicant)} className={applicant.applicant.include_scout_candidate ? 'btn green none-candidate' : 'btn green'}>スカウト候補に追加</a>
                            : <></>}
                            <a onClick={() => loadEntry(applicant)} href="#" className={scoutDisabled ? "candidate_scout_btn_disabled btn orange" : "btn orange"}>スカウト</a>
                            </div>
                            {applicant.applicant.scouted_recruitments.length > 0 ?
                                !checkApplicantDetailsPage() ?
                                    <div className="tag_box">
                                        <h5>スカウト済みの案件</h5>
                                        <ul>
                                        {applicant.applicant.scouted_recruitments && applicant.applicant.scouted_recruitments.map((scouted_recruitment: any ) => (
                                            <li key={'scouted_recruitment' + scouted_recruitment.id}>
                                                <a href={'/recruitments/' + scouted_recruitment.id} target="_blank">{scouted_recruitment.title}</a>
                                            </li>
                                        ))}
                                        </ul>
                                    </div>
                                : <></>
                            : <></>}
                        </div>
                    </li>
                    ))}
                    </ul>
                    <div>
                        <div className="icon"></div>
                        <ul className="pagination">
                            {current_page && current_page > 1 && (
                            <li className="arrow prev">
                                <a onClick={() => pageDecrement()}>
                                <img src="/assets/img/company/icon06.svg" alt="前へ" />
                                </a>
                            </li>
                            )}

                            {shaping_pagenation().map((n: any) => (
                            <li key={n} className={n === current_page ? 'active' : ''}>
                                <a onClick={() => updatePage(n)}>{n}</a>
                            </li>
                            ))}

                            {current_page && current_page < total_pages && (
                            <li className="arrow next">
                                <a onClick={() => pageIncrement()}>
                                <img src="/assets/img/company/icon07.svg" alt="次へ" />
                                </a>
                            </li>
                            )}
                        </ul>
                    </div>
                    {applicants !== undefined && applicants.length == 0 ?
                    <p>スカウト候補はいません</p> : <></>}
                    <p><span>{ selectedApplicants.length }</span>名 選択しています</p>
                    {!isCandidatePage() ?
                    <a onClick={(e) => saveCandidates(selectedApplicantIds, null)} className={selectedApplicants.length == 0 ? 'btn green none-candidate' : 'btn green'}>スカウト候補に一括追加</a>
                    : <a href="javascript:void(0)" onClick={(e) => saveCandidates(0, selectedApplicants)} className={selectedApplicants.length == 0 ? 'btn orange none-candidate' : 'btn orange'}>一括スカウト</a>}                    
                </div>
            </div>
        </section>
    </main>
    );
  }
  
  export default CandidateUnsentIndex;