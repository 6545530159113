import axios, { AxiosResponse } from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { API_BASE_ADDRESS } from "../../API";
import { recruiterUpdateAllStatusTypes } from "../../ActionTypes/recruiterUpdateAllStatusTypes";
import { fetchRecruiterUpdateAllStatusFailure, fetchRecruiterUpdateAllStatusSuccess } from "../../actions/recruiterUpdateAllStatusActions/recruiterUpdateAllStatusActions";


const getRecruiterUpdateAllStatus = ( status_id:any) => { 
  const config = {
    headers: { 
      Accept: 'application/json', 
      Authorization: localStorage.jobParachannelAuthToken 
    }
  };
    return axios.post(API_BASE_ADDRESS + '/recruitments/update_all' , {status_id: status_id}, config); 
 
}


function* fetchRecruiterUpdateAllStatusSaga(action: any) {
  try {
    const response: AxiosResponse = yield call(getRecruiterUpdateAllStatus,action.status_id);
    yield put(
      fetchRecruiterUpdateAllStatusSuccess({
        status: response.data,
      })
    );
  } catch (e:any) {
    yield put(
      fetchRecruiterUpdateAllStatusFailure({
        error: e.message
      })
    );
  }
}

function* RecruiterUpdateAllStatusSaga() {
  yield takeLatest(recruiterUpdateAllStatusTypes.FETCH_RECRUITER_UPDATE_ALL_STATUS_REQUEST, fetchRecruiterUpdateAllStatusSaga);
}
export default RecruiterUpdateAllStatusSaga;