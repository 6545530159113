import { useEffect, useState } from "react";
import MypageSideBar from "../../../components/MypageSideBar/MypageSideBar"
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchLoginRequest } from "../../../redax/actions/loginActions/loginActions";
import { RootState } from "../../../redax/reducers/rootReducer";
import { fetchCompaniesRequest } from "../../../redax/actions/companiesActions/companiesActions";
import { fetchRecruitersRequest } from "../../../redax/actions/recruitersActions/recruitersActions";
import { fetchSettingsRequest } from "../../../redax/actions/settingsActions/settingsActions";
import Loader from "../../loader/loader";
import Icon from '@mdi/react';
import { mdiDeleteCircle } from '@mdi/js';
import './settings.scss';

function RecruiterSettings() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [familyName, setFamylyName] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    const queryParams = new URLSearchParams();
    const [isActive, setIsActive] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [screen, setScreen] = useState(0);
    const [email, setEmail] = useState(null);
    const [recruitersList, setRecruiters] = useState([]);
    const [recruiter, setRecruiter] = useState<any>(null);
    const applicants = useSelector((state: RootState) => state.applicants);
    const recruiters = useSelector((state: RootState) => state.recruiters);
    const settings = useSelector((state: RootState) => state.settings);
    const [error, setError] = useState<boolean>(false);
    const [emailValidate, setemailValidate] = useState<boolean>(false);
    const pageTitle = '設定変更｜パラちゃんねる';
      document.title = pageTitle;
      const [loadScreen, setLoadScreen] = useState(false);

      useEffect(() => {
        if (localStorage.getItem('jobParachannelAuthToken')) {
        //   dispatch(fetchLoginRequest('', '',''))
        } else (
            navigate('/recruiter/login/')
        )
      }, [dispatch]);

    const loginData = useSelector((state: RootState) => state.login);


    useEffect(() => {
        if (loginData.pending) {
            // console.log(queryUrl);
            if(!loginData.login.is_init_complete) {
                navigate('/recruiter/init/');
            }
            setResourceId(loginData.login.resource.id);
            setFamylyName(loginData.login.resource.family_name);
            setIsActive(loginData.login.is_active ? 1 : 0);
            setRecruiter(loginData.login.resource);
            dispatch(fetchRecruitersRequest(loginData.login.company.id, 'get', 0));
        }
      }, [loginData.pending]);

    useEffect(() => {
        if (recruiters.pending) {         
            setLoadScreen(false);   
            setRecruiters(recruiters.recruiters);
        } else if(recruiters.error != null) {
            setLoadScreen(false);
            navigate('/error?code=500');
        }
    }, [recruiters]);

    useEffect(() => {
        if (settings.pending) {
            setLoadScreen(false);            
            setScreen(1);
        } else if(settings.error !== null) {
            setLoadScreen(false);
            setScreen(2);
        }
    }, [settings]);

      function logout() {
        if (localStorage.entryFlag) {
          localStorage.removeItem('entryFlag')
        }
        if (localStorage.jobParachannelAuthToken) {
          localStorage.removeItem('jobParachannelAuthToken')
          navigate('/');
          window.location.reload();
        }
      }
      function modalOpen () {
        setIsModalOpen(true)
      }
      function modalClose () {
        setIsModalOpen(false)
      }

      function statusSwitching (num: any) {
        if (window.confirm(num == 0 ? "ステータスをOFFにすると全ての求人が募集終了となります。\n本当によろしいですか。" : "ステータスを変更しますか？")) {
          setIsActive(num)
          const data = { is_active: num };
          dispatch(fetchCompaniesRequest(loginData.login.company.id, 'put', data));
        }
      };

      function deleteRecruiter(recruiter_id: any) {
        const result = window.confirm("担当者を削除します\n本当によろしいですか。")
        if(!result) return
        setLoadScreen(true);
        dispatch(fetchRecruitersRequest(loginData.login.company.id, 'delete', recruiter_id));
      }

      function emailChange (event: any) {
        const {name, value} = event.target;
        setEmail(value);
        SelectValidation(name, value);
      }

      function SelectValidation (name: any, value: any) {
        let mailformat = /^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+(\.[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (value === '') {
              setError(true);
              setemailValidate(false);
          } else {
              setError(false);
              if(mailformat.test(value)){
                setemailValidate(false);
            } else {
                setemailValidate(true);
            }
         }
      }

      function submit () {
        if(error || emailValidate) {
            return;
        }
        setLoadScreen(true);
        dispatch(fetchSettingsRequest(email, 'recruiter'));
      }
    return (
        <main id="company">
        <MypageSideBar resource={resourceId} familyName = {familyName}  currentList="RecruiterList" />
        {loadScreen ? <Loader /> : <></>}
        <section>
            <ul className="bread_crumb">
                <li><a href="/">トップ</a></li>
                <li><a href="/recruiter/">企業様マイページ</a></li>
                <li><a href="#">設定</a></li>
            </ul>
            <div className="ttl_box">
                <h2 className="ttl">設定</h2>
                <ul>
                <li><a href="#" onClick={logout}><img src="/assets/img/company/icon02.svg" alt=""/>ログアウト</a></li>
                </ul>
            </div>
            <div className="setting">
                <div className="cont1">
                    <h3 className="ttl">採用担当者</h3>
                    <div>
                        <div>
                        <dl>
                            <dt>採用担当者名</dt>
                            <dd>採用担当者アドレス</dd>
                        </dl>
                        {recruitersList.map((i: any, index: any) => (
                        <dl key={i.id} className="padd">
                            <dt>{i.name}</dt>
                            <dd>
                            {i.email}
                            <>
                            {recruitersList.length > 1 && i.id != recruiter.id ?
                            <button type="button" className="btn  btnDelete" onClick={() => deleteRecruiter(i.id)} style={{display: 'flex',alignItems: 'center'}}>
                                <><Icon path={mdiDeleteCircle} size={0.6} style={{marginRight: '4px'}} /> 削除</>
                            </button>
                            :<></>}
                            </>
                            </dd>
                        </dl>
                        ))}
                        </div>
                        <p className="note">メールアドレスを変更される場合は新規採用担当者を追加して不要なアカウントを削除してください。</p>
                        <a href="#" onClick={modalOpen}><img src="/assets/img/company/icon03.svg" alt=""/></a>
                    </div>
                </div>
                {/* <div className="cont2">
                    <h3 className="ttl">支払情報</h3>
                    <img src="/assets/img/company/banner02.png" alt=""/>
                </div> */}
                {/* <div className="cont3">
                    <h3 className="ttl">ステータス変更</h3>
                        <p>ステータスをOFFにすると、採用活動をおやすみします。</p>
                    <div>
                        <button className={`btn type3 ${isActive === 1 ? 'active' : ''}`} onClick={(e) => { e.preventDefault(); statusSwitching(1); }}>ON</button>
                        <button className={`btn type3 ${isActive === 0 ? 'active' : ''}`} onClick={(e) => { e.preventDefault(); statusSwitching(0); }}>OFF</button>
                    </div>
                </div> */}
                <a href="/contact/">退会をご希望の方</a>
            </div>
        </section>
        {isModalOpen ?
        <div className="modal_box">
            <div className="modal_inner">
                {screen === 0 ?
                <>
                    <h4>管理者の追加</h4>
                    <p className="read">
                        管理者に追加したいメールアドレスを入力して送信を押してください。<br/>
                        招待リンクが入力したメールアドレス宛へ送られます。
                    </p>
                    <form>
                        <input type="email" value={email || ''}  name="email" onChange={emailChange} placeholder="example@example.co.jp"/>
                        {error ? 
                            <div>
                            <span>必須項目です</span>
                            </div>
                        : <></>} 
                        {emailValidate ? 
                            <div>
                            <span>正しいメールアドレスの形式で入力してください</span>
                            </div>
                        : <></>}
                        <div className="btn_area">
                            <button onClick={submit} className="btn type3">送信する</button>
                        </div>
                    </form>
                </>
                :screen === 1 ? 
                <>
                    <h4>送信完了</h4>
                    <p className="read end">ご入力いただいたメールアドレス宛に招待が送信されました。</p>
                </>
                :screen === 2 ?
                <>
                    <h4>エラー</h4>
                    <p className="read end">エラーが発生しました。<br/>恐れ入りますがもう一度やり直してください。</p>
                </> 
                : <></>}

                <p className="close" onClick={modalClose}><span className="material-icons">close</span></p>
            </div>
            <div className="overlay" onClick={modalClose}></div>
        </div>
        : <></>}
      </main>
    );

}

export default RecruiterSettings;