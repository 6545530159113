import { recruiterUpdateAllStatusTypes } from "../../ActionTypes/recruiterUpdateAllStatusTypes";
import { RecruiterUpdateAllStatusActions, RecruiterUpdateAllStatusState } from "../../types/type";


const initialState: RecruiterUpdateAllStatusState = {
  pending: false,
  status: [],
  error: null,
};

export default (state = initialState, action: RecruiterUpdateAllStatusActions,) => {
  switch (action.type) {
    case recruiterUpdateAllStatusTypes.FETCH_RECRUITER_UPDATE_ALL_STATUS_REQUEST:
      return {
        ...state,
        pending: false
      };
      case recruiterUpdateAllStatusTypes.FETCH_RECRUITER_UPDATE_ALL_STATUS_SUCCESS:
      return {
        ...state,
        pending: true,
        status: action.payload.status,
        error: null
      };
    case recruiterUpdateAllStatusTypes.FETCH_RECRUITER_UPDATE_ALL_STATUS_FAILURE:
      return {
        ...state,
        pending: false,
        status: [],
        error: action.payload.error
      };
    default:
      return {
        ...state
      };
  }
};
