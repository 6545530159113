import { recruiterUpdateAllStatusTypes } from "../../ActionTypes/recruiterUpdateAllStatusTypes";
import { FetchRecruiterUpdateAllStatusRequest, FetchRecruiterUpdateAllStatusSuccessPayload, FetchRecruiterUpdateAllStatusSuccess, FetchRecruiterUpdateAllStatusFailurePayload, FetchRecruiterUpdateAllStatusFailure } from "../../types/type";


export const fetchRecruiterUpdateAllStatusRequest = ( status_id:any): FetchRecruiterUpdateAllStatusRequest => ({
  type: recruiterUpdateAllStatusTypes.FETCH_RECRUITER_UPDATE_ALL_STATUS_REQUEST,
  status_id,
});

export const fetchRecruiterUpdateAllStatusSuccess = (
  payload: FetchRecruiterUpdateAllStatusSuccessPayload
): FetchRecruiterUpdateAllStatusSuccess => ({
  type: recruiterUpdateAllStatusTypes.FETCH_RECRUITER_UPDATE_ALL_STATUS_SUCCESS,
  payload
});

export const fetchRecruiterUpdateAllStatusFailure = (
  payload: FetchRecruiterUpdateAllStatusFailurePayload
): FetchRecruiterUpdateAllStatusFailure => ({
  type: recruiterUpdateAllStatusTypes.FETCH_RECRUITER_UPDATE_ALL_STATUS_FAILURE,
  payload
});